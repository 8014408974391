<template>
  <div class="productRegionForm-container">
    <form-panel ref="form"
                :form="form"
                :queryUrl="queryUrl"
                :submitUrl="submitUrl"
                v-bind="submitConfig"
                :submitBefore="submitBefore"
                :submitSuccess="submitSuccessFunc"
                @update="update">
        <template slot="headerSlot">
          <v-button text="返回" @click="goBack"></v-button>
        </template>
      <col2-detail>
        <col2-block>
          <el-form-item label="产品域名称"
                        prop="productRegionName"
                        :rules="productRegionNameRules">
            <v-input v-model="form.productRegionName"
                     :maxlength="8" />
          </el-form-item>
          <el-form-item v-show="!defaultRegionIds"
                        label="授权对象"
                        :rules="[{ required: true, validator: regionIdsValid, trigger: 'blur1'}]"
                        prop="regionIds">
            <chosen-list-panel :list.sync="regionIds"
                               @select="toSelectRegionIds"
                               :beforeRemove="beforeRemove"></chosen-list-panel>
          </el-form-item>
          <el-form-item label="用户端APP"
                        prop="userAppCode"
                        :rules="[{ required: true, message: '请选择用户端APP', trigger: 'change'}]">
            <v-select2 v-model="form.userAppCode"
                       v-bind="userAppCodeParams"
                       placeholder="查询APP名称"></v-select2>
          </el-form-item>
          <el-form-item label="服务端APP"
                        prop="serviceAppCode"
                        :rules="[{ required: true, message: '请选择服务端APP', trigger: 'change'}]">
            <v-select2 v-model="form.serviceAppCode"
                       v-bind="serviceAppCodeParams"
                       placeholder="查询APP名称"></v-select2>
          </el-form-item>
          <el-form-item label="商家端APP">
            <v-select2 v-model="form.businessAppCode"
                       v-bind="businessAppCodeParams"
                       placeholder="查询APP名称"></v-select2>
          </el-form-item>
          <el-form-item label="吉祥物昵称"
                        prop="nickname"
                        :rules="nicknameRules">
            <v-input v-model="form.nickname"
                     :maxlength="3" />
          </el-form-item>
          <el-form-item label="吉祥物头像"
                        prop="headPicName"
                        :rules="[{ type:'array', required: true, message: '请选择吉祥物头像', trigger: 'change' }]">
            <v-uploader :action="uploadCoverURL"
                        :fileList.sync="form.headPicName"
                        operate="none" />
            <span>注：图片最大不超过2M</span>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <v-button v-if="showReturn"
                  text="返回"
                  @click="returnClick" />
      </template>
    </form-panel>
    <multi-select title="选择公司"
                  :isShow.sync="isShow"
                  :searchUrl="getproductRegionUrl"
                  :headers="productRegionTableHeader"
                  :searchParams="searchParams"
                  :responseParams="responseParams"
                  :responseKey="responseKey"
                  :selectable="selectable"
                  :beforeSave="beforeSave"
                  :backFill.sync="regionIds">
      <template #searchSlot>
        <v-input v-model="searchParams.regionName"
                 label="公司名称"></v-input>
        <v-input v-model="searchParams.communityName"
                 label="关联项目"></v-input>
        <v-input v-model="searchParams.productRegionName"
                 label="关联产品域"></v-input>
        <v-datepicker :appendToBody="false"
                      label="创建时间"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
    </multi-select>
  </div>
</template>
<script>
import {
  APPURL,
  createUrl,
  updateUrl,
  getDetailUrl,
  getproductRegionUrl,
  uploadURL
} from './api'
import { Col2Block, Col2Detail, MultiSelect, ChosenListPanel } from '@/components/bussiness'
import { vUploader } from 'components/control'

export default {
  name: 'ProductRegionForm',
  components: {
    Col2Block,
    Col2Detail,
    MultiSelect,
    ChosenListPanel,
    vUploader
  },
  props: {
    setBreadcrumb: {
      type: Boolean,
      default: true
    },
    submitText: String,
    showReturn: {
      type: Boolean,
      default: false
    },
    defaultRegionIds: Array,
    submitSuccess: Function
  },
  data () {
    const validateProductRegionName = (rule, value, callback) => {
      let regExp = /^[\w\u4e00-\u9fa5]+$/
      if (!regExp.test(value)) {
        callback(new Error('请正确输入产品域名称'))
      } else {
        callback()
      }
    }
    const validateNickname = (rule, value, callback) => {
      let regExp = /^[\w\u4e00-\u9fa5]+$/
      if (!regExp.test(value)) {
        callback(new Error('请正确输入吉祥物昵称'))
      } else {
        callback()
      }
    }
    return {
      queryUrl: getDetailUrl,
      submitUrl: this.$route.query.id ? updateUrl : createUrl,
      getproductRegionUrl: getproductRegionUrl,
      submitConfig: {
        submitContentType: 'application/json;charset=UTF-8',
        submitText: this.submitText
      },
      productRegionNameRules: [
        { required: true, message: '请填写产品域名称', trigger: 'blur' },
        { validator: validateProductRegionName, trigger: 'blur' }
      ],
      nicknameRules: [
        { required: true, message: '吉祥物昵称', trigger: 'blur' },
        { validator: validateNickname, trigger: 'blur' }
      ],
      uploadCoverURL: `${uploadURL}icon`,
      form: {
        productRegionName: '',
        userAppCode: '',
        serviceAppCode: '',
        businessAppCode: '',
        nickname: '',
        headPicName: []
      },
      userAppCodeParams: {
        searchUrl: `${APPURL}` + 1,
        request: {
          text: 'appName',
          value: 'id'
        },
        response: {
          text: 'appName'
        }
      },
      serviceAppCodeParams: {
        searchUrl: `${APPURL}` + 2,
        request: {
          text: 'appName',
          value: 'id'
        },
        response: {
          text: 'appName'
        }
      },
      businessAppCodeParams: {
        searchUrl: `${APPURL}` + 3,
        request: {
          text: 'appName',
          value: 'id'
        },
        response: {
          text: 'appName'
        }
      },
      bluetoothExtraParams: {
        communityId: ''
      },
      regionIds: [],
      editRegionIds: [],
      isShow: false,
      swalRegionId: false,
      searchParams: {
        regionName: '',
        communityName: '',
        productRegionName: '',
        startTime: '',
        endTime: ''
      },
      productRegionTableHeader: [
        {
          prop: 'regionName',
          label: '公司名称'
        }, {
          prop: 'communityNames',
          label: '关联项目'
        }, {
          prop: 'productRegionName',
          label: '关联产品域'
        }, {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      responseParams: {
        id: 'id',
        name: 'regionName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      }
    }
  },
  created () {
    let { id } = this.$route.query
    if (this.setBreadcrumb) {
      id ? this.$setBreadcrumb('编辑') : this.$setBreadcrumb('新增')
    }
  },
  mounted () {
    let { id } = this.$route.query
    id && this.$refs.form.getData({ id: id })
  },
  watch: {
    defaultRegionIds (newValue) {
      this.regionIds = newValue
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
      this.$router.go(-1)
    },
    selectable (data) {
      return data.hasProductRegion === 1
    },
    update (data) {
      this.form.id = data.id
      this.form.productRegionName = data.productRegionName
      this.form.userAppCode = data.userAppCode
      this.form.serviceAppCode = data.serviceAppCode
      this.form.businessAppCode = data.businessAppCode
      this.form.nickname = data.nickname
      this.regionIds = data.regions.map(item => {
        return {
          text: item.regionName,
          id: item.regionId
        }
      })
      this.editRegionIds = data.regions.map(item => {
        return {
          text: item.regionName,
          id: item.regionId
        }
      })
      this.form.headPicName = data.headPicName ? [{ url: data.headPicName }] : []
    },
    async beforeSave (list) {
      let { id } = this.$route.query
      if (id) {
        this.changeMultiData(list)
        if (this.swalRegionId) {
          let isOk = await this.$confirm('确认修改授权对象？', {
            title: '提示'
          })
          this.isShow = isOk !== true
          return isOk
        } else {
          this.isShow = false
          return true
        }
      } else {
        this.isShow = false
        return true
      }
    },
    changeMultiData (list) {
      this.editRegionIds.forEach(item => {
        this.swalRegionId = true
        let swalMsg = false
        list.forEach(data => {
          if (data.id === item.id) {
            swalMsg = true
          }
        })
        this.swalRegionId = swalMsg !== true
      })
    },
    async beforeRemove (list) {
      let { id } = this.$route.query
      if (id) {
        this.changeMultiData(list)
        if (this.swalRegionId) {
          let isOk = await this.$confirm('确认修改授权对象？', {
            title: '提示'
          })
          return isOk
        } else {
          return true
        }
      } else {
        return true
      }
    },
    toSelectRegionIds () {
      this.isShow = true
    },
    regionIdsValid (rule, value, callback) {
      if (this.regionIds.length > 0) {
        callback()
      } else {
        callback(new Error('请选择授权对象！'))
      }
    },
    submitBefore (data) {
      data.headPicName = this.form.headPicName[0].url
      data.regionIds = this.regionIds.map(item => item.id)

      // 操作日志增加操作对象参数 规则：产品域名称-授权对象
      let regionNames = this.regionIds.map(item => item.text).join(',')
      let dataObject = `${data.productRegionName}-${regionNames}`
      this.$set(data, 'dataObject', dataObject)

      return true
    },
    submitSuccessFunc () {
      if (this.submitSuccess) {
        this.submitSuccess()
        return false
      }
      return true
    },
    returnClick () {
      this.$emit('returnClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.productRegionForm-container {
  height: 100%;
}
</style>
