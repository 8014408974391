var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productRegionForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              queryUrl: _vm.queryUrl,
              submitUrl: _vm.submitUrl,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccessFunc,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _vm.showReturn
                      ? _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.returnClick },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "产品域名称",
                        prop: "productRegionName",
                        rules: _vm.productRegionNameRules,
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { maxlength: 8 },
                        model: {
                          value: _vm.form.productRegionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "productRegionName", $$v)
                          },
                          expression: "form.productRegionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.defaultRegionIds,
                          expression: "!defaultRegionIds",
                        },
                      ],
                      attrs: {
                        label: "授权对象",
                        rules: [
                          {
                            required: true,
                            validator: _vm.regionIdsValid,
                            trigger: "blur1",
                          },
                        ],
                        prop: "regionIds",
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          list: _vm.regionIds,
                          beforeRemove: _vm.beforeRemove,
                        },
                        on: {
                          "update:list": function ($event) {
                            _vm.regionIds = $event
                          },
                          select: _vm.toSelectRegionIds,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户端APP",
                        prop: "userAppCode",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户端APP",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "查询APP名称" },
                            model: {
                              value: _vm.form.userAppCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userAppCode", $$v)
                              },
                              expression: "form.userAppCode",
                            },
                          },
                          "v-select2",
                          _vm.userAppCodeParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "服务端APP",
                        prop: "serviceAppCode",
                        rules: [
                          {
                            required: true,
                            message: "请选择服务端APP",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "查询APP名称" },
                            model: {
                              value: _vm.form.serviceAppCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "serviceAppCode", $$v)
                              },
                              expression: "form.serviceAppCode",
                            },
                          },
                          "v-select2",
                          _vm.serviceAppCodeParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商家端APP" } },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "查询APP名称" },
                            model: {
                              value: _vm.form.businessAppCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "businessAppCode", $$v)
                              },
                              expression: "form.businessAppCode",
                            },
                          },
                          "v-select2",
                          _vm.businessAppCodeParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "吉祥物昵称",
                        prop: "nickname",
                        rules: _vm.nicknameRules,
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { maxlength: 3 },
                        model: {
                          value: _vm.form.nickname,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nickname", $$v)
                          },
                          expression: "form.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "吉祥物头像",
                        prop: "headPicName",
                        rules: [
                          {
                            type: "array",
                            required: true,
                            message: "请选择吉祥物头像",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadCoverURL,
                          fileList: _vm.form.headPicName,
                          operate: "none",
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(_vm.form, "headPicName", $event)
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(_vm.form, "headPicName", $event)
                          },
                        },
                      }),
                      _c("span", [_vm._v("注：图片最大不超过2M")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("multi-select", {
        attrs: {
          title: "选择公司",
          isShow: _vm.isShow,
          searchUrl: _vm.getproductRegionUrl,
          headers: _vm.productRegionTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          selectable: _vm.selectable,
          beforeSave: _vm.beforeSave,
          backFill: _vm.regionIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.regionIds = $event
          },
          "update:back-fill": function ($event) {
            _vm.regionIds = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "公司名称" },
                  model: {
                    value: _vm.searchParams.regionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "regionName", $$v)
                    },
                    expression: "searchParams.regionName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "关联项目" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "关联产品域" },
                  model: {
                    value: _vm.searchParams.productRegionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "productRegionName", $$v)
                    },
                    expression: "searchParams.productRegionName",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    appendToBody: false,
                    label: "创建时间",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }