// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}product/region/manage/list`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}orderSettle/exportOrderSettle`
// 授权对象select2
const regionIdURL = `${API_CONFIG.butlerBaseURL}region/getRegionBusinessRelationship?fromSelect2=0`
// app列表
const APPURL = `${API_CONFIG.butlerBaseURL}appInfo/selectAppinfo?appType=`
// 授权对象列表
const getproductRegionUrl = `${API_CONFIG.butlerBaseURL}region/getRegionBusinessRelationship`
// 上传图片
const uploadURL = `${API_CONFIG.uploadURL}?module=`
// 新增
const createUrl = `${API_CONFIG.butlerBaseURL}product/region/add`
// 详情
const getDetailUrl = `${API_CONFIG.butlerBaseURL}product/region/detail`
// 编辑
const updateUrl = `${API_CONFIG.butlerBaseURL}product/region/update`

export {
  getListURL,
  exportListURL,
  regionIdURL,
  APPURL,
  getproductRegionUrl,
  uploadURL,
  createUrl,
  getDetailUrl,
  updateUrl
}
